import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { Injectable } from '@angular/core';
import { NotificationFeed } from './notification-feed.model';
import { PagedResults } from '../../common/paging/paged-results.model';
import { NotificationFeedEvent } from './notification-feed-event.model';
import { ActionResponse } from '../../common/mobiati-http/action-response.model';
import { MarkAsSeenResponseModel } from './mark-as-seen-response.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class UserNotificationsService {
  private unreadCount$: BehaviorSubject<number> = new BehaviorSubject<number>(
    0
  );
  openNotificationCenterSubject: Subject<void> = new Subject();

  constructor(private http: MobiatiHttpService) {}

  getNotificationFeedById(id: number): Promise<NotificationFeed> {
    return this.http.get<NotificationFeed>(`/feed/${id}`);
  }

  getNotificationFeed(
    // tslint:disable-next-line:variable-name
    per_page = null,
    page = null
  ): Promise<PagedResults<NotificationFeed[]>> {
    return this.http.get<PagedResults<NotificationFeed[]>>(
      `/feed`,
      this.http.getHttpParams({
        per_page,
        dismissed: false,
        page
      })
    );
  }

  markAsSeen(feedsIds: number[]): Promise<MarkAsSeenResponseModel> {
    return this.http.patch<MarkAsSeenResponseModel>(`/feed/mark-as-seen`, {
      feeds_ids: feedsIds
    });
  }

  archiveAll(): Promise<ActionResponse> {
    return this.http.patch<ActionResponse>(`/feed/archive-all`);
  }

  archiveOne(feedId: number): Promise<ActionResponse> {
    return this.http.patch<ActionResponse>(`/feed/${feedId}/archive`);
  }

  getUnreadNotifications(): Promise<number> {
    return this.http.getAuthToken() !== null
      ? this.http.get<number>(`/feed/unread`)
      : Promise.resolve(0);
  }

  getUnreadCount(): Observable<number> {
    return this.unreadCount$.asObservable();
  }

  updateUnreadCount(newCount): void {
    this.unreadCount$.next(newCount);
  }

  async onNewUserNotification(event: NotificationFeedEvent): Promise<any> {
    try {
      this.unreadCount$.next(event.unread_count);

      const feed = await this.getNotificationFeedById(event.id);
    } catch (e) {
      // something wrong with notification processing; just log
      console.log(e);
    }
  }
}
