import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { AppCommonModule } from '../common/common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from '../app-routing.module';
import { SettingsComponent } from './settings/settings.component';
import { PatientChatModule } from './patient-chat/patient-chat.module';
import { SupportModule } from './support/support.module';
import { ScheduleModule } from './schedule/schedule.module';
import { SharedModule } from './shared/shared.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  GroupPracticesSelectComponent,
  NavigationComponent,
  NavigationService
} from './navigation';
import { AnalyticsScheduleModule } from './schedule/analytics-schedule.module';

@NgModule({
  declarations: [
    MainComponent,
    NavigationComponent,
    SettingsComponent,
    GroupPracticesSelectComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatBadgeModule,
    MatIconModule,
    TranslateModule,
    AppRoutingModule,
    PatientChatModule,
    ScheduleModule,
    AnalyticsScheduleModule,
    SupportModule,
    SharedModule,
    MatButtonModule
  ],
  exports: [MainComponent],
  providers: [NavigationService]
})
export class MainModule {}
