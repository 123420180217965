import { Inject, Injectable } from '@angular/core';
import { LoginRequest } from './login-request.model';
import { LoginResponse } from './login-response.model';
import { RefreshTokenRequest } from './refresh-token-request.model';
import {
  API_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  ANALYTICS_API_URL
} from '../../app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserCredential } from 'firebase/auth';
import { AnalyticsUser } from '../user/user-data.model';
import { firstValueFrom, map } from 'rxjs';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string,
    @Inject(ANALYTICS_API_URL) private readonly analyticsApiUrl: string,

    @Inject(CLIENT_ID) private clientId: number,
    @Inject(CLIENT_SECRET) private clientSecret: string
  ) {}

  async hasAccessToAnalytics(
    userCredentials: UserCredential
  ): Promise<LoginResponse> {
    const firebaseUser = userCredentials.user;
    const firebaseAccessToken = await firebaseUser.getIdToken();
    const firebaseRefreshToken = await firebaseUser.refreshToken;
    return firstValueFrom(
      this.http
        .get<AnalyticsUser>(`${this.analyticsApiUrl}/Users/MyInfo`, {
          headers: new HttpHeaders().set(
            'Authorization',
            `Bearer ${firebaseAccessToken}`
          )
        })
        .pipe(
          map((response) => {
            return Promise.resolve({
              token_type: 'Bearer',
              access_token: firebaseAccessToken,
              refresh_token: firebaseRefreshToken,
              practice_guid: response.practiceList?.[0]?.practiceId ?? null
            } as LoginResponse);
          })
        )
    );
  }

  async hasAccessToEngagement(
    username: string,
    password: string,
    role: string,
    deviceUuid?: string,
    scope: string = '',
    practiceGuid?: string
  ): Promise<LoginResponse> {
    try {
      return Promise.resolve(
        await this.logInPassword(username, password, role, deviceUuid)
      );
    } catch (e) {
      return Promise.resolve({
        token_type: 'Bearer',
        access_token: null,
        refresh_token: null,
        practice_guid: null
      } as LoginResponse);
    }
  }

  logInPassword(
    username: string,
    password: string,
    role: string,
    deviceUuid?: string,
    scope: string = '',
    practiceGuid?: string
  ): Promise<LoginResponse> {
    const request = {
      grant_type: 'password',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      scope,
      username,
      password,
      role,
      practice_guid: practiceGuid,
      device_uuid: deviceUuid
    } as LoginRequest;

    const url =
      request.practice_guid !== undefined && request.practice_guid !== null
        ? `${this.apiUrl}/oauth/token`
        : `${this.apiUrl}/oauth/token-without-practice`;

    return this.http.post<LoginResponse>(url, request).toPromise();
  }

  logInClient(
    scope: string = '',
    practiceGuid?: string
  ): Promise<LoginResponse> {
    const request = {
      grant_type: 'client_credentials',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      scope,
      practice_guid: practiceGuid
    } as LoginRequest;

    const url =
      request.practice_guid !== undefined && request.practice_guid !== null
        ? `${this.apiUrl}/oauth/token`
        : `${this.apiUrl}/oauth/token-without-practice`;

    return this.http.post<LoginResponse>(url, request).toPromise();
  }

  refreshToken(refreshToken: string, scope: string): Promise<LoginResponse> {
    const request = {
      grant_type: 'refresh_token',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      refresh_token: refreshToken,
      scope
    } as RefreshTokenRequest;

    return this.http
      .post<LoginResponse>(`${this.apiUrl}/oauth/token`, request)
      .toPromise();
  }

  logout(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/oauth/logout`).toPromise();
  }
}
