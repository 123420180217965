import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AlertsService } from '../../common/alerts/alerts.service';
import { AuthService } from '../../common/authentication/auth.service';
import { UserService } from '../../common/user';
import { PracticeService } from '../../common/practice';
import { UserData } from '../../common/user/user-data.model';
import { NativeAppService } from '../../common/native-app-service/native-app.service';
import { DeviceService } from '../../common/device-service/device.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { from, Subject } from 'rxjs';
import { TwoFactorAuthenticationDialogService } from './two-factor-authentication-dialog/two-factor-authentication-dialog.service';
import { TwoFactorAuthenticationDialogCloseReason } from './two-factor-authentication-dialog/two-factor-authentication-dialog.types';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Router } from '@angular/router';
import { LoginResponse } from 'src/app/common/authentication/login-response.model';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('password') passwordRef: ElementRef;

  @Output() logInSuccess = new EventEmitter<boolean>();

  isSubmitting: boolean;
  platform: string;

  form: FormGroup = this.fb.group({
    email: '',
    password: '',
    rememberMe: true
  });

  public firebaseAuth = getAuth();

  fixForAutofillOnIos$ = this.form.valueChanges
    .pipe(
      // wait for 500ms to be sure the autofill is finished with the password field
      debounceTime(500),
      tap((changes) => {
        // manually fetch the value from nativeElement
        const passVal = this.passwordRef.nativeElement.value;
        // if the value saved in the form is different from the input, manually update it
        if (passVal !== changes.password) {
          this.form.get('password').setValue(passVal);
        }
      }),
      untilDestroyed(this)
    )
    .subscribe();

  private readonly mfaRequired$: Subject<string> = new Subject<string>();

  constructor(
    private readonly alertsService: AlertsService,
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly practiceService: PracticeService,
    private readonly nativeService: NativeAppService,
    private readonly deviceService: DeviceService,
    private readonly fb: FormBuilder,
    private readonly twoFactorAuthenticationDialogService: TwoFactorAuthenticationDialogService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.platform = this.deviceService.getPlatform();
    this.platform = (this.platform ? this.platform : 'ios').toUpperCase();

    this.subscribeToMFARequired();
  }

  async onLogIn(formValue): Promise<any> {
    this.isSubmitting = true;
    try {
      // Validate User Credentials in Firebase
      const userCredentials = await signInWithEmailAndPassword(
        this.firebaseAuth,
        formValue.email,
        formValue.password
      );

      // Check if the user exists in Analytics
      const analyticsResponse = await this.authService.hasAccessToAnalytics(
        userCredentials
      );

      // Check if the user exists in Engagement
      const engagementResponse = await this.authService.hasAccessToEngagement(
        formValue.email,
        formValue.password,
        'staff',
        this.userService.getDeviceUuid(formValue.email)
      );

      this.userService.setUserData(
        {
          ...engagementResponse,
          username: formValue.email
        } as UserData,
        formValue.rememberMe,
        undefined,
        {
          ...analyticsResponse,
          username: formValue.email
        } as UserData
      );

      // Load Engagement Info
      await this.loadEngagementInfo(engagementResponse);

      // Load Analytics Info
      const hasMobileAccess = await this.loadAnalyticsInfo(analyticsResponse);

      if (
        engagementResponse.access_token != null &&
        engagementResponse.access_token !== ''
      ) {
        this.router.navigate(['patient-chat']);
      } else if (hasMobileAccess) {
        this.router.navigate(['my-schedule']);
      }
      this.logInSuccess.emit(true);
    } catch (e) {
      const error = e.error;
      if (error) {
        if (error.message === 'INVALID_LOGIN_CREDENTIALS') {
          this.alertsService.showApiError('The user credentials are incorrect');
        }

        if (error.device_uuid) {
          this.userService.setDeviceUuid(error.device_uuid, formValue.email);
        }

        switch (error.error) {
          case 'unknown_device':
            this.alertsService.showApiWarning(e);
            break;
          case 'mfa_required':
            this.mfaRequired$.next(error.email);
            break;
          default:
            this.alertsService.showApiError(e);
            break;
        }
      }
    } finally {
      // stop spinner
      this.isSubmitting = false;
    }
  }

  async loadEngagementInfo(engagementResponse: LoginResponse) {
    if (
      engagementResponse.access_token != null &&
      engagementResponse.access_token !== ''
    ) {
      this.practiceService.setPracticeGuid(engagementResponse.practice_guid);
      await this.practiceService.loadPracticeIfNeeded();
      await this.userService.loadUser();
    }
  }

  async loadAnalyticsInfo(analyticsResponse: LoginResponse): Promise<boolean> {
    let hasMobileAccess = false;
    if (
      analyticsResponse.practice_guid != null &&
      analyticsResponse.practice_guid !== ''
    ) {
      this.practiceService.setAnalyticsPracticeGuid(
        analyticsResponse.practice_guid
      );
      const analyticsUser = await this.userService.loadAnalyticsUser();
      hasMobileAccess = analyticsUser.permissions.some(
        (a) => a.value === 'MobileApp' && a.key === 1300
      );
    }
    return hasMobileAccess;
  }

  async onPasswordKeyDown($event): Promise<any> {
    if ($event.key === 'Enter') {
      await this.onLogIn(this.form.value);
    }
  }

  onTermsClicked(): void {
    this.nativeService.openExternalUrl('https://www.modento.io/terms');
  }

  onPrivacyClicked(): void {
    this.nativeService.openExternalUrl('https://www.modento.io/privacy');
  }

  private subscribeToMFARequired(): void {
    this.mfaRequired$
      .pipe(
        switchMap((email) =>
          this.twoFactorAuthenticationDialogService
            .open(email, this.form.value.email)
            .afterClosed()
            .pipe(
              filter(
                (closeReason: TwoFactorAuthenticationDialogCloseReason) =>
                  closeReason ===
                  TwoFactorAuthenticationDialogCloseReason.VERIFIED
              ),
              switchMap(() => from(this.onLogIn(this.form.value)))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
