import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { initializeApp } from 'firebase/app';
import { environment } from '../../../environments/environment';

initializeApp(environment.googleAuth);

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class GoogleAuthModule {}
