import { Injectable } from '@angular/core';
import { MobiatiHttpService } from '../../common/mobiati-http/mobiati-http.service';
import { Subscription } from 'rxjs';
import { Schedule } from './schedule.model';
import { Patient } from '../../common/patient/patient.model';
import { AnalyticsHttpService } from '../../common/mobiati-http/analytics-http.service';
import { AnalyticsSchedule } from './analytics-schedule-summary.model';
import { PracticeService } from '../../common/practice';

@Injectable({ providedIn: 'root' })
export class ScheduleService {
  schedule: any;

  protected loadedData = false;
  // Has focus event subscription
  protected appFocusChangeSub: Subscription;

  constructor(
    private readonly http: MobiatiHttpService,
    private readonly practiceService: PracticeService,
    private readonly analyticsHttp: AnalyticsHttpService
  ) {}

  async getSchedule(dateStr: string): Promise<Schedule> {
    const fullSchedule = await this.http.get<Schedule>(
      `/staff/liveops/schedule/${dateStr}`
    );

    // add patient to appt
    this.transformAppointments(fullSchedule);
    return Promise.resolve(fullSchedule);
  }

  /**
   * Add patient objects to respective appointments models for easy of use.
   * Add end of appointment date calculated from start date and length.
   * @param fullSchedule
   */
  private transformAppointments(fullSchedule: Schedule) {
    const patientMap = {};
    fullSchedule.patients.forEach(
      (patient) =>
        (patientMap[patient.id] = Object.assign(new Patient(), patient))
    );
    fullSchedule.live.forEach((appt) => {
      appt.patient = patientMap[appt.patient_id];
      appt.end_scheduled_for = new Date(
        new Date(appt.scheduled_for).getTime() + 60000 * appt.length
      );
    });
  }

  async getWeekScheduleSummary(
    dateStart: string,
    dateEnd: string
  ): Promise<any> {
    return await this.analyticsHttp.get<AnalyticsSchedule>(
      `ScheduleMetrics/GetPortalScheduleSummary?practiceId=${this.practiceService.getAnalyticsPracticeGuid()}&dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
  }

  async getMyScheduleSummary(dateStr: string): Promise<any> {
    return await this.analyticsHttp.get<AnalyticsSchedule>(
      `ScheduleMetrics/GetPortalScheduleSummary?practiceId=${this.practiceService.getAnalyticsPracticeGuid()}&dateStart=${dateStr}&dateEnd=${dateStr}`
    );
  }
}
