import { InjectionToken } from '@angular/core';

export const SERVER_URL = new InjectionToken<string>('serverUrl');
export const API_URL = new InjectionToken<string>('apiUrl');
export const CLIENT_ID = new InjectionToken<number>('clientId');
export const CLIENT_SECRET = new InjectionToken<string>('clientSecret');
export const GOOGLE_AUTH = new InjectionToken<string>('GOOGLE_AUTH');
export const ANALYTICS_API_URL = new InjectionToken<string>(
  'ANALYTICS_API_URL'
);

export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const SERVER_ERROR = 500;
export const NOT_FOUND = 404;
