import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { User } from './user.model';
import { MobiatiHttpService } from '../mobiati-http/mobiati-http.service';
import { isEmpty, nonEmpty } from '../utils/string-utils.helper';
import { environment } from '../../../environments/environment';
import { AnalyticsUser, UserData } from './user-data.model';
import { ActionResponse } from '../mobiati-http/action-response.model';
import { DeviceRegisterRequest } from './device-register-request.model';
import { DeviceService } from '../device-service/device.service';
import uuidv1 from 'uuid/v1.js';
import uuidv4 from 'uuid/v4.js';
import { TeamMember } from './team-member.model';
import { AppVersionRequest } from './app-version-request.model';
import { MD5 } from 'crypto-js';
import { AnalyticsHttpService } from '../mobiati-http/analytics-http.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private static COOKIE_OPTIONS = {
    expires: new Date(3000, 1),
    secure: environment.production,
    storeUnencoded: false
  } as CookieOptions;

  private user: User;
  private analyticsUser: AnalyticsUser;
  private teamMember: TeamMember;
  private userData: UserData;
  private analyticsUserData: UserData;
  private deviceId: string;

  constructor(
    private http: MobiatiHttpService,
    private readonly analyticsHttp: AnalyticsHttpService,
    private cookieService: CookieService,
    private deviceService: DeviceService
  ) {
    // aproach to be compatible with what Dashboard uses
    this.userData = this.cookieService.getObject('currentUser') as UserData;
    this.analyticsUserData = this.cookieService.getObject(
      'currentAnalyticsUser'
    ) as UserData;

    this.http.setAuthToken(
      this.userData ? this.userData.access_token : undefined
    );

    this.analyticsHttp.setAuthToken(
      this.analyticsUserData ? this.analyticsUserData.access_token : undefined
    );

    const storedUser = JSON.parse(localStorage.getItem('user'));
    const storedAnalyticsUser = JSON.parse(
      localStorage.getItem('analytics_user')
    );

    if (storedUser) {
      console.log('Restored user from local storage');
      this.user = storedUser;
    }

    if (storedAnalyticsUser) {
      console.log('Restored Analytics user from local storage');
      this.analyticsUser = storedAnalyticsUser;
    }

    this.teamMember = JSON.parse(localStorage.getItem('teamMember'));
    this.deviceId = localStorage.getItem('deviceId');
  }

  /**
   * Loads practice data based on guid if not loaded already.
   */
  async loadUser(): Promise<User> {
    this.setUser(await this.http.get('/user'));
    this.setTeamMember(await this.http.get('/staff/team-member'));
    return Promise.resolve(this.getUser());
  }

  async loadAnalyticsUser(): Promise<AnalyticsUser> {
    this.setAnalyticsUser(
      await this.analyticsHttp.get(
        `Users/LoginPracticeUser2?practiceId=${this.analyticsUserData.practice_guid}`
      )
    );
    return Promise.resolve(this.getAnalyticsUser());
  }

  setUser(user: User) {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  setAnalyticsUser(user: AnalyticsUser) {
    this.analyticsUser = user;
    localStorage.setItem('analytics_user', JSON.stringify(this.analyticsUser));
  }

  getUser(): User {
    return this.user;
  }

  getAnalyticsUser(): AnalyticsUser {
    return this.analyticsUser;
  }

  setTeamMember(teamMember: TeamMember) {
    this.teamMember = teamMember;
    localStorage.setItem('teamMember', JSON.stringify(this.teamMember));
  }

  getTeamMember(): TeamMember {
    return this.teamMember;
  }

  setUserData(
    userData: UserData,
    remember: boolean,
    cookieOptions?: CookieOptions,
    analyticsUserData?: UserData
  ) {
    this.userData = userData;
    this.analyticsUserData = analyticsUserData;
    this.http.setAuthToken(userData.access_token);
    this.analyticsHttp.setAuthToken(analyticsUserData.access_token);
    if (remember) {
      this.cookieService.putObject(
        'currentUser',
        userData,
        cookieOptions || UserService.COOKIE_OPTIONS
      );
      this.cookieService.putObject(
        'currentAnalyticsUser',
        analyticsUserData,
        cookieOptions || UserService.COOKIE_OPTIONS
      );
    }

    if (userData.device_uuid) {
      this.setDeviceUuid(userData.device_uuid, userData.username);
    }
  }

  getUserData() {
    return this.userData;
  }

  getAnalyticsUserData() {
    return this.analyticsUserData;
  }

  isLoggedIn(): boolean {
    return nonEmpty(
      this.http.getAuthToken() || this.analyticsHttp.getAuthToken()
    );
  }

  clearUserInfo() {
    delete this.user;
    this.http.setAuthToken(undefined);
    this.analyticsHttp.setAuthToken(undefined);
    localStorage.removeItem('user');
    localStorage.removeItem('analytics_user');
    this.cookieService.remove('currentUser');
    this.cookieService.remove('currentAnalyticsUser');
  }

  private initDeviceIdIfNeeded() {
    if (isEmpty(this.deviceId)) {
      this.deviceId = String(uuidv1() + uuidv4()).replace(/-/g, '');
      localStorage.setItem('deviceId', this.deviceId);
    }
  }

  async registerDeviceForPush(token: string): Promise<ActionResponse> {
    this.initDeviceIdIfNeeded();
    const deviceRequest = {
      token,
      platform: this.deviceService.getPlatform(),
      device_id: this.deviceId,
      device_name: this.deviceService.getDeviceName(),
      device_model: this.deviceService.getFullModentoClientInfo(),
      device_version: '',
      app_version: this.deviceService.getModentoHostAppVersionInt()
    } as DeviceRegisterRequest;
    return this.http.post<ActionResponse>('/devices', deviceRequest);
  }

  async unregisterDevice(): Promise<ActionResponse> {
    if (nonEmpty(this.deviceId)) {
      return this.http.delete<ActionResponse>(`/devices/${this.deviceId}`);
    } else {
      return Promise.resolve({ success: true } as ActionResponse);
    }
  }

  updateAppVersion(): Promise<ActionResponse> {
    this.initDeviceIdIfNeeded();

    const appVersionRequest = {
      platform: this.deviceService.getPlatform(),
      device_id: this.deviceId,
      app_version: this.deviceService.getModentoHostAppVersionInt(),
      app: 'Modento GO'
    } as AppVersionRequest;
    return this.http.post<ActionResponse>('/apps', appVersionRequest);
  }

  async getShareAuthUrl(): Promise<any> {
    return this.http.post<any>(`${this.http.apiUrl}/auth/share`, this.userData);
  }

  // tslint:disable-next-line:typedef
  getDeviceUuidCookieName(username: string) {
    const env = environment.production ? '' : environment.envName + '.';
    const name = username === '' ? '' : username + '.';
    return 'modento.' + env + MD5(name) + 'device_uuid';
  }

  // tslint:disable-next-line:typedef
  setDeviceUuid(deviceUuid: string, username: string) {
    if (this.getDeviceUuid(username) === null) {
      const exp = new Date();
      exp.setFullYear(exp.getFullYear() + 1);
      const cookieOptions = {
        expires: exp,
        storeUnencoded: false
      } as CookieOptions;
      this.cookieService.put(
        this.getDeviceUuidCookieName(username),
        deviceUuid,
        cookieOptions
      );
    }
  }

  // tslint:disable-next-line:typedef
  getDeviceUuid(username: string) {
    return (
      this.cookieService.get(this.getDeviceUuidCookieName('')) ||
      this.cookieService.get(this.getDeviceUuidCookieName(username)) ||
      null
    );
  }
}
