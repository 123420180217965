import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AlertsService } from '../../../common/alerts/alerts.service';
import { ScheduleService } from '../schedule.service';
import { Materialize } from '../../../materialize';
import { applyDropdownFixForMobile } from '../../../common/utils/materialize-utils.helper';
import { NativeAppService } from '../../../common/native-app-service/native-app.service';
import { Subscription } from 'rxjs';
import { DeviceService } from '../../../common/device-service/device.service';
import { isEmpty } from '../../../common/utils/string-utils.helper';
import {
  AnalyticsScheduleAppointment,
  AnalyticsScheduleSummary,
  DayItem,
  AnalyticsProviderMetric
} from './analytics-schedule.model';
import { UserService } from '../../../common/user';

declare const M: Materialize;

@Component({
  selector: 'my-schedule',
  templateUrl: './analytics-schedule.component.html',
  styleUrls: ['./analytics-schedule.component.scss']
})
export class AnalyticsScheduleComponent
  implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean;
  date: string;
  selectedProvider: string;
  weekScheduleSummary: AnalyticsScheduleSummary;
  dayScheduleSummary: AnalyticsScheduleSummary;
  defaultProviderOption: string;
  allProviderMetrics: AnalyticsProviderMetric[];
  providerMetrics: AnalyticsProviderMetric;
  appointments: AnalyticsScheduleAppointment[];
  currentWeekDays: DayItem[];

  // Has focus event subscription
  protected appFocusChangeSub: Subscription;

  constructor(
    private readonly scheduleService: ScheduleService,
    private readonly alertsService: AlertsService,
    private readonly nativeService: NativeAppService,
    private readonly deviceService: DeviceService,
    private readonly userService: UserService
  ) {}

  async ngOnInit() {
    this.date = this.toDateStr(new Date());
    this.defaultProviderOption = 'ALL_PROVIDERS';
    this.currentWeekDays = this.getCurrentWeekDates(new Date());

    // By default, display All Providers
    this.selectedProvider = this.defaultProviderOption;

    this.reloadSchedule().then(() => {
      this.applyFilters();
    });

    this.appFocusChangeSub = this.nativeService
      .focusChangeObservable()
      .subscribe((hasFocus) => {
        if (hasFocus) {
          this.reloadSchedule().then();
        }
      });
  }

  onDatePickerChange(event) {
    this.currentWeekDays = this.getCurrentWeekDates(event);
    this.date = this.toDateStr(event);
    this.reloadSchedule();
  }

  async onWeekViewDateChange(day: DayItem) {
    this.currentWeekDays.forEach((d) => (d.isSelected = false));
    day.isSelected = true;
    if (this.date !== day.formattedDate) {
      this.date = day.formattedDate;
      await this.getDaySummary();
      this.applyFilters();
    }
  }

  async getDaySummary() {
    try {
      this.isLoading = true;
      this.dayScheduleSummary = await this.scheduleService.getMyScheduleSummary(
        this.date
      );
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterViewInit(): void {
    this.updateMaterializeUI();
  }

  private toDateStr(date: Date) {
    return date.toLocaleDateString('en-US');
  }

  async reloadSchedule() {
    // For whatever reason, if the date is still empty, set it to current date.
    if (isEmpty(this.date)) {
      this.date = this.toDateStr(new Date());
    }

    try {
      this.isLoading = true;
      const weekStartDate = this.currentWeekDays[0].formattedDate;
      const weekEndDate = this.currentWeekDays[this.currentWeekDays.length - 1]
        .formattedDate;
      this.weekScheduleSummary = await this.scheduleService.getWeekScheduleSummary(
        weekStartDate,
        weekEndDate
      );
      this.applyFilters();
    } catch (e) {
      this.alertsService.showApiError(e);
    } finally {
      this.isLoading = false;
    }
  }

  private updateMaterializeUI() {
    const elems = document.querySelectorAll('select');
    M.FormSelect.init(elems, {
      dropdownOptions: {
        coverTrigger: false,
        onOpenStart: () => {
          applyDropdownFixForMobile();
        }
      }
    });
  }

  sumField(data, field) {
    return data.reduce(
      (accumulator, currentValue) => accumulator + (currentValue[field] || 0),
      0
    );
  }

  async applyFilters() {
    const weekScheduleProviderSummaries = this.weekScheduleSummary
      .providerSummaries;
    if (this.selectedProvider === this.defaultProviderOption) {
      this.providerMetrics = {
        appointmentsCount: this.sumField(
          weekScheduleProviderSummaries,
          'appointmentsCount'
        ),
        scheduledMinutes: this.sumField(
          weekScheduleProviderSummaries,
          'scheduledMinutes'
        ),
        productionScheduled: this.sumField(
          weekScheduleProviderSummaries,
          'productionScheduled'
        ),
        productionGoal: this.sumField(
          weekScheduleProviderSummaries,
          'productionGoal'
        ),
        unscheduledTxForProvider: this.sumField(
          weekScheduleProviderSummaries,
          'unscheduledTXForSummary'
        ),
        daysScheduled: []
      };

      this.allProviderMetrics = weekScheduleProviderSummaries.map((summary) => {
        return {
          userId: summary.userId,
          name: `${summary.firstName} ${summary.lastName}`,
          role: summary.role,
          appointmentsCount: summary.appointmentsCount,
          scheduledMinutes: summary.scheduledMinutes,
          productionScheduled: summary.productionScheduled,
          daysScheduled: []
        };
      });
    } else {
      if (this.dayScheduleSummary === undefined) {
        await this.getDaySummary();
      }
      const providerSummary = this.dayScheduleSummary?.providerSummaries.find(
        (provider) => provider.userId === this.selectedProvider
      );

      if (providerSummary === undefined) {
        this.providerMetrics = undefined;
        this.appointments = [];
        return;
      }

      const weekScheduleProviderSummary = weekScheduleProviderSummaries.find(
        (provider) => provider.userId === this.selectedProvider
      );
      this.providerMetrics = {
        userId: providerSummary.userId,
        appointmentsCount: providerSummary?.appointmentsCount ?? 0,
        scheduledMinutes: providerSummary?.scheduledMinutes ?? 0,
        productionScheduled: providerSummary?.productionScheduled ?? 0,
        productionGoal: providerSummary?.productionGoal ?? 0,
        unscheduledTxForProvider: providerSummary?.unscheduledTXForSummary ?? 0,
        daysScheduled: weekScheduleProviderSummary.dayMetrics.map((item) => {
          return {
            date: item.scheduleDate,
            isScheduled: item.productionScheduled > 0
          };
        })
      };

      this.appointments = providerSummary.appointments.map((appointment) => {
        let analyticsAppointment: AnalyticsScheduleAppointment = {} as AnalyticsScheduleAppointment;
        if (appointment.appointmentId === '-1') {
          analyticsAppointment.time = appointment.start;
          analyticsAppointment.endTime = appointment.end;
          analyticsAppointment.name = 'Open';
          analyticsAppointment.status = 'open';
        } else if (appointment.appointmentId === '-999') {
          analyticsAppointment.time = appointment.start;
          analyticsAppointment.endTime = appointment.end;
          analyticsAppointment.name = 'Closed';
          analyticsAppointment.status = 'closed';
        } else {
          analyticsAppointment.time = appointment.start;
          const patient = this.dayScheduleSummary.patients.find(
            (patient) => patient.patientId === appointment.patientId
          );
          analyticsAppointment.name = patient
            ? `${patient.firstName} ${patient.lastName}`
            : null;
          analyticsAppointment.status =
            appointment.confirmationDetails === 'Confirmed'
              ? 'confirmed'
              : 'not_confirmed';
          analyticsAppointment.codes = appointment.procedures
            .map((proc) => proc.adaCode)
            .join(',');
        }
        return analyticsAppointment;
      });
    }
    setTimeout(() => this.updateMaterializeUI(), 0);
  }

  formatDate(dateString: string) {
    if (dateString == undefined) return null;
    const date = new Date(dateString);
    const timeString = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
    return timeString;
  }

  onProviderChange() {
    this.applyFilters();
  }

  onProviderClick(provider: AnalyticsProviderMetric) {
    this.selectedProvider = provider.userId;
    this.applyFilters();
  }

  ngOnDestroy(): void {
    if (this.appFocusChangeSub) {
      this.appFocusChangeSub.unsubscribe();
    }
  }

  reloadScheduleOnBlur() {
    // iOS reloads on blur because (change) is signaled during interaction with date picker
    if (this.deviceService.isiOS()) {
      this.reloadSchedule();
    }
  }

  reloadScheduleOnChange() {
    // Android reloads on (change) as this is how date picker should work
    if (this.deviceService.isAndroid()) {
      this.reloadSchedule();
    }
  }

  /**
   * Get the current week dates based on the given input date
   */
  getCurrentWeekDates(inputDate) {
    const currentDate = new Date(inputDate);
    const currentDay = currentDate.getDay();
    const diffToSunday = currentDay === 0 ? 0 : -currentDay;
    const sunday = new Date(currentDate);
    sunday.setDate(currentDate.getDate() + diffToSunday);

    const daysOfWeek = [
      { label: 'Sunday', shortLabel: 'SU' },
      { label: 'Monday', shortLabel: 'MO' },
      { label: 'Tuesday', shortLabel: 'TU' },
      { label: 'Wednesday', shortLabel: 'WE' },
      { label: 'Thursday', shortLabel: 'TH' },
      { label: 'Friday', shortLabel: 'FR' },
      { label: 'Saturday', shortLabel: 'SA' }
    ];

    return daysOfWeek.map((day, index) => {
      const dayDate = new Date(sunday);
      dayDate.setDate(sunday.getDate() + index);

      return {
        label: day.label,
        shortLabel: day.shortLabel,
        date: dayDate.getDate(),
        isSelected: dayDate.toDateString() === currentDate.toDateString(),
        isToday: dayDate.toDateString() === currentDate.toDateString(),
        formattedDate: this.toDateStr(dayDate)
      };
    });
  }
}
